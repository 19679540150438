export const colors = {
  blue: '#3eb0ef',
  green: '#a4d037',
  purple: '#ad26b4',
  yellow: '#fecd35',
  red: '#f05230',
  orange: '#ffa500',
  darkgrey: '#15171A',
  midgrey: '#738a94',
  lightgrey: '#c5d2d9',
  whitegrey: '#e5eff5',
  grey6: '#666666',
  white: '#ffffff',
  black: '#000000',
  pink: '#fa3a57',
  brown: '#a3821a',
  // darkmode: color(var(--darkgrey) l(+2%)),
  darkmode: '#191b1f',
  //allweb: '#d2b996',
  nude: '#f7e9d6',
  redbrown: '#9e5413',
  darkblue: '#1f325f'
};